<script>
  import { onMount,afterUpdate } from "svelte";
  import { fade, fly } from 'svelte/transition';
  import { Pulse } from 'svelte-loading-spinners';
  import Select from 'svelte-select';

  import records from '/src/countries.json';

  const countriesEndpoint = "https://api.airtable.com/v0/appOzDBNy5a1gid3U/Countries?api_key=keyvZ5ckkETxfmtu7&sort%5B0%5D%5Bfield%5D=Name&sort%5B0%5D%5Bdirection%5D=asc";

  let countries = [];
  let countrySearch = [];
  let allCountries = [];
  let isLoading = true;
  let currencies = [];
  let voltages = [];
  let plugs = [];
  let languages = [];
  // Filters
  let selectedCurrency = '';
  let selectedVoltage = '';
  let selectedPlug = '';
  let selectedLanguage = '';

  onMount(async function () {
    const urlParams = new URLSearchParams(window.location.search);
    const pullFromAPI = urlParams.has('pull');

    if (pullFromAPI) {
      const response = await fetch(countriesEndpoint);
      const data = await response.json();
      allCountries = countries = data.records;

      if (data.offset) {
        const response2 = await fetch(countriesEndpoint + '&offset=' + data.offset);
        const data2 = await response2.json();
        Array.prototype.push.apply(allCountries, data2.records);
        // countries = allCountries;
        if (data2.offset) {
          const response3 = await fetch(countriesEndpoint + '&offset=' + data2.offset);
          const data3 = await response3.json();
          Array.prototype.push.apply(allCountries, data3.records);
        }
      }
    } else {
      allCountries = records.records;
    }

    countries = allCountries;
    isLoading = false;

    allCountries.forEach((item) => {
      if (item.fields.Assets) {
        countrySearch[item.fields.Name] = item.fields.Assets[0].thumbnails.small.url;
      } else {
        countrySearch[item.fields.Name] = null
      }

      // Build currency filter
      item.fields['Currency Display (from Currency)'].forEach((currency) => {
        if (!currencies.includes(currency)) {
          currencies.push(currency);
        }
      });

      // Build voltage filter
      item.fields['Power Voltage (from Volts)'].forEach((voltage) => {
        if (!voltages.includes(voltage)) {
          voltages.push(voltage);
        }
      });

      // Build plug filter
      item.fields['Type (from Plugs)'].forEach((plug) => {
        if (!plugs.includes(plug)) {
          plugs.push(plug);
        }
      });

      // Build language filter
      item.fields['Language (from Languages Spoken)'].forEach((language) => {
        if (!languages.includes(language)) {
          languages.push(language);
        }
      })
    });

    currencies = currencies.sort();
    voltages = voltages.sort();
    plugs = plugs.sort();
    languages = languages.sort();
  });

  afterUpdate(() => {
    let search = document.getElementById('autocomplete-input');

    M.Autocomplete.init(search, 
      {
        data: countrySearch,
        onAutocomplete: function(item) {
          // Hide all but the match
          filterByCountryName(item)
        }
      }
    );
  });

  function filterByCountryName(name) {
    allCountries.forEach((item) => {
      if (item.fields.Name === name) {
        countries = [item]
        return false
      }
    });

    countries = countries
  };

  function resetList() {
    countries = allCountries
    document.getElementById('autocomplete-input').value=''
    return false
  }

  function toggleSearch() {
    let filterBar = document.querySelector('.advanced-search');
    let searchArrow = document.querySelector('.search-arrow');

    filterBar.classList.toggle('h0');

    if (searchArrow.textContent === 'expand_more') {
      searchArrow.textContent = 'expand_less';
    } else {
      searchArrow.textContent = 'expand_more';
      //clearAllFilters();
    }
  }

  function clearAllFilters() {
    let clearButtons = document.querySelectorAll('.clearSelect');
    if (clearButtons) {
      clearButtons.forEach((item) => {
        item.click()
      });
    }
  }

  function advancedSearch() {
    //console.log(selectedCurrency, selectedVoltage, selectedPlug, selectedLanguage);

    countries = allCountries.filter(function(v, i) {
      return (
        (v.fields['Currency Display (from Currency)'].includes(selectedCurrency) || selectedCurrency === '') &&
        (v.fields['Power Voltage (from Volts)'].includes(selectedVoltage) || selectedVoltage === '') &&
        (v.fields['Type (from Plugs)'].includes(selectedPlug) || selectedPlug === '') &&
        (v.fields['Language (from Languages Spoken)'].includes(selectedLanguage) || selectedLanguage === '')
      );
    });
  }

  function handleCurrencyFilter(event) {
    if(event.detail) {
      selectedCurrency = event.detail.value;
    } else {
      selectedCurrencies = [];
    }

    advancedSearch();
  }

  function handleVoltageFilter(event) {
    if(event.detail) {
      selectedVoltage = event.detail.value
    } else {
      selectedVoltage = '';
    }

    advancedSearch();
  }

  function handlePlugFilter(event) {
    if(event.detail) {
      selectedPlug = event.detail.value
    } else {
      selectedPlug = '';
    }

    advancedSearch();
  }

  function handleLanguageFilter(event) {
    if(event.detail) {
      selectedLanguage = event.detail.value
    } else {
      selectedLanguage = '';
    }

    advancedSearch();
  }

  function clearCurrency(event) {
    selectedCurrency = '';
    advancedSearch();
  }

  function clearVoltage(event) {
    selectedVoltage = '';
    advancedSearch();
  }

  function clearPlug(event) {
    selectedPlug = '';
    advancedSearch();
  }

  function clearLanguage(event) {
    selectedLanguage = '';
    advancedSearch();
  }



</script>

<main>
  <div class="row pink lighten-5 mb-2 header">
    <div class="col s12 m4 l4">
      <h1 class="pink-text text-darken-1">Country Traveler <a href="https://www.getrevue.co/profile/travel-tech-nerd" target="_blank" class="hide-on-med-and-up right circle"><img src='newsletter_200.png' alt="Travel Tech Nerd icon"></a></h1>
      <small class="grey-text text-darken-3">Helpful travel info. All in one place.</small>
    </div>
    <div class="col s8 m5 l4">
      <div class="row" style="margin-bottom:0">
        <div class="input-field col s12 mt-2 search">
          <i class="material-icons prefix">language</i>
          <input type="text" id="autocomplete-input" class="autocomplete">
          <label for="autocomplete-input">Start typing a country...</label>
          <a class="reset" href="#top" on:click={resetList}><i class="material-icons">clear</i></a>
        </div>
      </div>
    </div>
    <div class="col s4 m2 l2">
      <p class="mt-3"><a on:click={toggleSearch} class="advanced-search-link" href="#top">Advanced Search <i class="material-icons search-arrow">expand_more</i></a></p>
    </div>
    <div class="col m1 l2">
      <a href="https://www.getrevue.co/profile/travel-tech-nerd" target="_blank" class="hide-on-small-only right my-2 circle"><img src='newsletter_200.png' alt="Travel Tech Nerd icon"></a>
    </div>
    <div class="col s12 advanced-search h0">
      <div class="row">
        <div class="col s12 m3 offset-m1">
          <Select id="currency-filter"
            containerClasses="filter-control"
            items={currencies} 
            placeholder="Currency"
            showIndicator=true
            on:select={handleCurrencyFilter}
            on:clear={clearCurrency}
          >
          </Select>
        </div>
        <div class="col s12 m2">
          <Select id="voltage-filter"
            containerClasses="filter-control"
            items={voltages} 
            placeholder="Voltage"
            showIndicator=true
            on:select={handleVoltageFilter}
            on:clear={clearVoltage}
          >
          </Select>
        </div>
        <div class="col s12 m2">
          <Select id="plug-filter"
            containerClasses="filter-control"
            items={plugs} 
            placeholder="Plug"
            showIndicator=true
            on:select={handlePlugFilter}
            on:clear={clearPlug}
          >
          </Select>
        </div>
        <div class="col s12 m3">
          <Select id="language-filter"
            containerClasses="filter-control"
            items={languages} 
            placeholder="Language"
            showIndicator=true
            on:select={handleLanguageFilter}
            on:clear={clearLanguage}
          >
          </Select>
        </div>
      </div>
      
    </div>
  </div>

  <div class="results">
    <div class="row">
      <div class="col s12">
        {#if isLoading}
        <Pulse size="60" color="#c51162" unit="px" duration="1s"></Pulse>
        {:else}
        <p class="white-text my-0">Displaying {countries.length} {#if countries.length == 1 }country/territory{:else}countries/territories{/if}</p>
        {/if}
      </div>
    </div>
  {#each countries as country, i}
      {#if i % 4 === 0}
      <div class="row">
        {#each Array(4) as _,j}
          {#if countries[i/4*4 + j]}
          <div class="col s12 l3">
            <div class="card z-depth-1" in:fly={{y:80, duration:1500}}  out:fade={{ duration: 500 }}>
              {#if countries[i/4*4 + j].fields.Assets}
              <div class="card-image" style="background-image: url({countries[i/4*4 + j].fields.Assets[0].thumbnails.large.url});"></div>
              {/if}
              <div class="card-content">
                <div class="row">
                  <div class="col s9">
                    <p class="card-title ">
                      {countries[i/4*4 + j].fields.Name}
                    </p>
                  </div>
                  <div class="col s3 img-container">
                    {#if countries[i/4*4 + j].fields.Assets}
                    <img src="{countries[i/4*4 + j].fields.Assets[0].thumbnails.small.url}" alt="{countries[i/4*4 + j].fields.Name} flag" class="right" />
                    {/if}</div>
                </div>
                <p><span class="grey-text" style="text-decoration: underline">Currency</span></p>
                {#if countries[i/4*4 + j].fields['Currency Display (from Currency)']}
                <ul>
                {#each countries[i/4*4 + j].fields['Currency Display (from Currency)'] as currency}
                  {#if selectedCurrency == currency}
                  <li class="grey lighten-2 filter-option">
                    {currency}
                  </li>
                  {:else}
                  <li class="grey lighten-4 filter-option">
                    {currency}
                  </li>
                  {/if}
                {/each}
                </ul>
                {/if}

                <p class="mt-1"><span  class="grey-text" style="min-width:70px; display:inline-block;">Voltage: </span>  
                  {#if countries[i/4*4 + j].fields['Power Voltage (from Volts)']}
                  {#each countries[i/4*4 + j].fields['Power Voltage (from Volts)'] as volts}
                    {#if selectedVoltage == volts}  
                      <span class="grey lighten-2 filter-option">{volts}</span>
                    {:else}
                      <span class="grey lighten-4 filter-option">{volts}</span>
                    {/if}
                  {/each}
                  {/if}
                </p>
                <div class="row" style="margin-top:2em;">
                  {#if countries[i/4*4 + j].fields['image (from Plugs)']}
                  {#each countries[i/4*4 + j].fields['image (from Plugs)'] as plug}
                    <div class="col s6 center-align">
                      <img alt="type plug" src="{plug.url}" class="plug" />   
                    </div>
                  {/each}
                  {/if}
                </div>

                <p><span class="grey-text" style="text-decoration: underline">Official Languages</span></p>
                {#if countries[i/4*4 + j].fields['Language (from Languages Spoken)']}
                <ul>
                {#each countries[i/4*4 + j].fields['Language (from Languages Spoken)'] as language}
                  {#if selectedLanguage == language}
                    <li class="grey lighten-2 filter-option">{language}</li>
                  {:else}
                    <li class="grey lighten-4 filter-option">{language}</li>
                  {/if}
                {/each}
                </ul>
                {/if}
              </div>
            </div>
          </div>
          {/if}
        {/each}
      </div>
      {/if}
  {/each}
  </div>
</main>

<style>

  

</style>